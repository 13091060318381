<app-home-banner></app-home-banner>


<!-- section start -->
	<!-- ================ -->
	<div class="clearfix light-gray-bg object-non-visible" data-animation-effect="fadeInUpSmall"
		data-effect-delay="200">
    <div class="container" style="padding: 50px 0px;">
      <div class="col-md-9">
        <h1>Why ChurchDiary</h1>
        <div class="separator-2"></div>
        <p class="lead">Manage your church efectively</p>
        <p>
          We know ministery is not a easy job. It is very hard to manage people, Families, ministrial groups,
          event, anouncement, givings and etc...! That the reason we are comming to you with a simple solution
          called ChurchDiary.
        </p>
        <p>
          ChurchDiary empowers your Church administration teams with the right kind of tools to manage your church activity with ease,
        </p>
		<p>
			We exist to help churches thrive! Powerful, easy to use church management for church members, contributions, 
			Ministiral Teams (Groups), Church Events, Instant Notification, Church Family management, 
			Announcement, custom reporting and much more! PC/MAC/iOS/Android. 
			Choose cloud-enabled or run it on your network. Over 100K churches and 150K ministry leaders 
			moved to ChurchDiary Find out why they are making the switch!
		  </p>

		  <h3>Create your free account now</h3>
		  <div>
			<a [routerLink]="['/account']" routerLinkActive="router-link-active"  class="btn btn-default">Click here to create your account</a>
			<a href="mailto:churchdiary2021@gmail.com" routerLinkActive="router-link-active"  class="btn btn-secondary">Requst a Demo</a>
		  </div>
      </div>
      <div class="col-md-3">
        <img src="../../../assets/img/mobile_Ui.jpg" />
      </div>

		</div>
		<!-- main start -->
		<!-- ================ -->
		<div class="section  dark-bg pattern-6">
			<div class="container">
				<div class="call-to-action">
					<div class="row">
						<div class="col-md-8">
							<h1 class="title text-center">We offer more possibilities to meet your every need.</h1>
						</div>
						<div class="col-md-4">
							<div class="text-center">
								<a href="#" class="btn btn-default btn-lg" [routerLink]="['/account']" routerLinkActive="router-link-active"> Create Account</a>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<!-- main end -->

	</div>
	<!-- section end -->


	<!-- page-top start-->
	<!-- ================ -->
	<div id="page-top" class="page-top object-non-visible" data-animation-effect="fadeInUpSmall"
		data-effect-delay="100">
		<div class="clearfix">
			<div class="col-md-12">
				<h1 class="text-center title">Services</h1>
				<div class="separator"></div>
				<p class="text-center">Refer our top features</p>
				<div class="row grid-space-20">
					<div class="col-md-12 col-lg-5">
						<br class="hidden-sm hidden-xs">
						<br class="hidden-sm hidden-xs">
						<img src="../../../assets/img/protal_image.jpg" alt="" class="object-non-visible center-block"
							data-animation-effect="fadeInUp" data-effect-delay="0">
					</div>
					<div class="col-md-6 col-lg-3">
						<br class="hidden-xs">
						<div class="box-style-3 object-non-visible" data-animation-effect="fadeInUpSmall"
							data-effect-delay="0">
							<div class="icon-container default-bg circle">
								<i class="fa fa-users"></i>
							</div>
							<div class="body">
								<h2>Manage Church Members (People)</h2>
								<p>It is very important to manage your church people to build a storng and
                  efective church community. Church Diary provide a simple and effective
                  way to manage your church members details
                </p>
								<a href="page-services.html" class="link"><span>Read More</span></a>
							</div>
						</div>
						<div class="box-style-3 object-non-visible" data-animation-effect="fadeInUpSmall"
							data-effect-delay="0">
							<div class="icon-container default-bg circle">
								<i class="fa fa-bold"></i>
							</div>
							<div class="body">
								<h2>Birthday calendar</h2>
								<p>ChurchDiary sends birthday Greetings to your church members automatically on behalf of your church
                  as well as remind you and your church members to wish them on their birthday.
                </p>
								<a href="page-services.html" class="link"><span>Read More</span></a>
							</div>
						</div>
						<div class="box-style-3 object-non-visible" data-animation-effect="fadeInUpSmall"
							data-effect-delay="200">
							<div class="icon-container default-bg circle">
								<i class="fa fa-check"></i>
							</div>
							<div class="body">
								<h2>Wedding Anniversary</h2>
								<p>ChurchDiary also provide option to remind your church member`s (People)
                  Wedding aniversary and help you to share wishes
                </p>
								<a href="page-services.html" class="link"><span>Read More</span></a>
							</div>
						</div>
						<div class="box-style-3 object-non-visible" data-animation-effect="fadeInUpSmall"
							data-effect-delay="200">
							<div class="icon-container default-bg circle">
								<i class="fa fa-calendar"></i>
							</div>
							<div class="body">
								<h2>Events</h2>
								<p>ChurchDiary provide options to manage your church events very effectively
                				</p>
								<a href="page-services.html" class="link"><span>Read More</span></a>
							</div>
						</div>
						<br class="hidden-xs hidden-sm">
					</div>
					<div class="col-md-6 col-lg-3">
						<br class="hidden-xs">
						<div class="box-style-3 object-non-visible" data-animation-effect="fadeInUpSmall"
							data-effect-delay="0">
							<div class="icon-container default-bg circle">
								<i class="fa fa-leaf"></i>
							</div>
							<div class="body">
								<h2>Effective Communication</h2>
								<p>Church Diary provide you a effective communication through sending bulk email and
                  SMS to your church members (People), Church Members Groups
                </p>
								<a href="page-services.html" class="link"><span>Read More</span></a>
							</div>
						</div>
						<div class="box-style-3 object-non-visible" data-animation-effect="fadeInUpSmall"
							data-effect-delay="200">
							<div class="icon-container default-bg circle">
								<i class="fa fa-laptop"></i>
							</div>
							<div class="body">
								<h2>Church Members Groups</h2>
								<p>We know church is a building block of small small ministrial groups. We are
                  providing option to create church ministrial groups and providing option
                  to communicate within the groups.
                </p>
								<a href="page-services.html" class="link"><span>Read More</span></a>
							</div>
						</div>
						<div class="box-style-3 object-non-visible" data-animation-effect="fadeInUpSmall"
							data-effect-delay="400">
							<div class="icon-container default-bg circle">
								<i class="fa fa-dollar"></i>
							</div>
							<div class="body">
								<h2>Instant Notifcation</h2>
								<p>We Provide a option to send instant notification to your church members</p>
								<a href="page-services.html" class="link"><span>Read More</span></a>
							</div>
						</div>
						<br class="hidden-xs">
					</div>
				</div>
			</div>
		</div>
	</div>
	<!-- page-top end -->


