export class UserAccount  {
  userAccountId: string;
  userAccountNumber: number;
  name: string;
  email: string;
  password: string;
  confirmPassword:string;
  isEmailVerificationLinkSend: boolean;
  isEmailVerified: boolean;
  phone: string;
  isPhoneNumberVerified: boolean;
  startDate: string;
  expiryDate: string;
  organisationName: string;
}
