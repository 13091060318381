import { environment } from "src/environments/environment";

export const APIUrl = {
  createUserAccount : `${environment.apiEndPoint}/v1/Portal/CreateAccount`,
  requestDemo : `${environment.apiEndPoint}/v1/Portal/RequestDemo`
};


export const Response = {
  InsertSuccess : `ISUS`,
};
