import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeaderComponent } from './shared/header/header.component';
import { TopBarComponent } from './shared/top-bar/top-bar.component';
import { AccountModule } from './account/account.module';
import { HomeModule } from './home/home.module';
import { FooterComponent } from './shared/footer/footer.component';
import { CreateAccountComponent } from './shared/create-account/create-account.component';
import { FormsModule } from '@angular/forms';
import { AngularFireModule } from "@angular/fire";
import { AngularFireAuthModule } from "@angular/fire/auth";
import { HttpClientModule } from '@angular/common/http';
import { UnderConstructionComponent } from './under-construction/under-construction.component';

var firebaseConfig = {
  apiKey: "AIzaSyAOKQZcfXBZpewD6UUPdCoSdtA_aoM8VCg",
  authDomain: "churchdiary-8a06f.firebaseapp.com",
  databaseURL: "https://churchdiary-8a06f-default-rtdb.firebaseio.com",
  projectId: "churchdiary-8a06f",
  storageBucket: "churchdiary-8a06f.appspot.com",
  messagingSenderId: "44202698336",
  appId: "1:44202698336:web:9772deee42c6b4a7b28505",
  measurementId: "G-DTBMXN0VGN"
};

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    TopBarComponent,
    FooterComponent,
    CreateAccountComponent,
    UnderConstructionComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    AccountModule,
    HomeModule,
    FormsModule,
    AngularFireModule.initializeApp(firebaseConfig),
    AngularFireAuthModule,
    HttpClientModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
