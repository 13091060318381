import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';

@Component({
  selector: 'app-top-bar',
  templateUrl: './top-bar.component.html',
  styleUrls: ['./top-bar.component.scss']
})
export class TopBarComponent implements OnInit {

  constructor() { }
  @ViewChild("loginToPortal", { static : true }) loginToPortal:ElementRef ;

  ngOnInit(): void {
  }

  openPortal(){
    this.loginToPortal.nativeElement.click();
  }

}
