import { Injectable } from '@angular/core';
import { APIUrl } from 'src/app/constants/constants';
import { ResponseModel } from 'src/app/models/shared/response.model';
import { RequestDemoModel } from 'src/app/models/User/request-demo.model';
import { UserAccount } from 'src/app/models/User/user-account.model';
import { environment } from 'src/environments/environment';
import { HttpService } from '../shared/http.service';

@Injectable({
  providedIn: 'root',
})
export class AccountService {
  constructor(private _httpService: HttpService) {}

  createAccount(useraccount: UserAccount) {
    return this._httpService.post(APIUrl.createUserAccount, useraccount);
  }

  requestDemo(requestDemo:RequestDemoModel){
    return this._httpService.post(APIUrl.requestDemo, requestDemo);
  }
}
