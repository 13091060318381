<div style="height: 80px;">&nbsp;</div>
<!-- banner start -->
<!-- ================ -->
<div class="banner">
  <!-- slideshow start -->
  <!-- ================ -->
  <div class="slideshow white-bg">
    <!-- slider revolution start -->
    <!-- ================ -->
    <div class="slider-banner-container">
      <div class="slider-banner">
        <ul>
          <!-- slide 1 start -->
          <li data-transition="fade" data-slotamount="7" data-masterspeed="1000" data-saveperformance="on"
            data-title="Slide 1">

            <!-- main image -->
            <img src="assets/img/slider-1.jpg" alt="slidebg1" data-bgposition="center top" data-bgfit="cover"
              data-bgrepeat="no-repeat">


            <!-- LAYER NR. 1 -->
            <div class="tp-caption large_text " style="color: #013886;" data-x="left" data-y="170"
              data-speed="200" data-start="0" data-end="10000" data-endspeed="200" data-splitin="chars"
              data-elementdelay="0.07" data-endelementdelay="0.1" data-splitout="chars" style="text-shadow: none; color: #013886;">Create your free account now...!
            </div>

            <!-- LAYER NR. 2 -->
            <div class="tp-caption sfr small_thin_dark text-center tp-resizeme" data-x="left" data-y="220"
              data-speed="600" data-start="0" data-end="10000" data-endspeed="600">Church Diary is a free web based
              software
              <br> that help you to maintain your church members details, Church ministerial groups, <br>Announcement
              etc...!
            </div>

            <!-- LAYER NR. 2 -->
            <div class="tp-caption sfr small_thin_dark text-center tp-resizeme" data-x="180" data-y="320"
              data-speed="600" data-start="0" data-end="10000" data-endspeed="600">
              <div class="text-center">
                <a [routerLink]="['/account']" routerLinkActive="router-link-active"  class="btn btn-default">Click here to create your account</a>
              </div>
            </div>

            <!-- LAYER NR. 3 -->
            <div class="tp-caption sft" data-x="center" data-y="500" data-speed="600" data-start="0" data-end="10000"
              data-endspeed="600">
              <a href="#page-top" class="btn btn-lg black smooth-scroll"><i
                  class="fa fa-angle-double-down fa-2x"></i></a>
            </div>

          </li>
          <!-- slide 1 end -->

          <!-- slide 2 start -->
          <li data-transition="fade" data-slotamount="7" data-masterspeed="1000" data-saveperformance="on"
            data-title="Slide 2">

            <!-- main image -->
            <img src="assets/img/slider-2.png" alt="slidebg1" data-bgposition="center top" data-bgfit="cover"
              data-bgrepeat="no-repeat">


            <!-- LAYER NR. 1 -->
            <div class="tp-caption large_text " style="text-shadow: none; color: #013886;" data-x="left" data-y="170"
              data-speed="200" data-start="0" data-end="10000" data-endspeed="200" data-splitin="chars"
              data-elementdelay="0.07" data-endelementdelay="0.1" data-splitout="chars">Manage your church events efectively
            </div>

            <!-- LAYER NR. 2 -->
            <div class="tp-caption sfr small_thin_dark text-center tp-resizeme" data-x="left" data-y="220"
              data-speed="600" data-start="0" data-end="10000" data-endspeed="600">ChurchDiary helps churches to orgainise, <br>Manage
              events very effectively thourgh it simplified mobile app..
            </div>

             <!-- LAYER NR. 2 -->
             <div class="tp-caption sfr small_thin_dark text-center tp-resizeme" data-x="180" data-y="320"
             data-speed="600" data-start="0" data-end="10000" data-endspeed="600">
             <div class="text-center">
               <a [routerLink]="['/account']" routerLinkActive="router-link-active"  class="btn btn-default">Click here to create your account</a>
             </div>
           </div>

          </li>
          <!-- slide 2 end -->
        </ul>
      </div>
    </div>
    <!-- slider revolution end -->
  </div>
  <!-- slideshow end -->
</div>
<!-- banner end -->
