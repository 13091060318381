import { Component, OnInit } from '@angular/core';
import { ResponseModel } from 'src/app/models/shared/response.model';
import { UserAccount } from 'src/app/models/User/user-account.model';
import { EntityUser } from 'src/app/models/User/user-model';
import { AccountService } from 'src/app/services/account/account.service';

@Component({
  selector: 'app-create-account',
  templateUrl: './create-account.component.html',
  styleUrls: ['./create-account.component.scss']
})
export class CreateAccountComponent implements OnInit {
  account: UserAccount;
  response: ResponseModel<string>;
  loaderStatus:boolean=false;
  passwordMissmatch:string;
  
  constructor(private _accountService: AccountService) { }

  ngOnInit(): void {
    this.account = new UserAccount();
    this.response = new ResponseModel<string>();
  }

  createUserAccount() {
    debugger;
    if (this.account.password !== this.account.confirmPassword) {
      this.passwordMissmatch = "Password and confirm password should be same";
      return;
    }

    this.loaderStatus = true;
    this._accountService
      .createAccount(this.account)
      .subscribe((response: ResponseModel<string>) => {
        this.response = response;
        this.loaderStatus = false;
      });
  }


  tryagain(){
    this.response = new  ResponseModel<string>();
  }

}
