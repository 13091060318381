<div class="limiter">
  <div class="container-login100">
    <div class="wrap-login100">
      <div class="login100-form">
        <div class="success-card" *ngIf="response.response==='ISUS'">
          <div class="success-icon">
            <span class="glyphicon glyphicon-ok icon" ></span><br><br>
            Success
            <br>
            <br>
            <br>
            Congrats...!({{account.name}}) church your account is created successfully..
            Please verify your email({{account.email}}), We already send an email with login details
            <br>
            <br>
            <a (click)="cancel()" style="cursor: pointer;">Click Here to close</a>
          </div>
        </div>
        <div class="failure-card" *ngIf="response.response &&response.response!=='ISUS'">
          <div class="failure-icon">
            <span class="glyphicon glyphicon-remove icon" ></span><br><br>
            Failure
            <br>
            <br>
            <br>
            {{response.errorMessage}}
            <br>
            <br>
            <a (click)="cancel()" style="cursor: pointer;">Click Here to close</a><br>
            <a (click)="tryagain()" style="cursor: pointer;">Try again</a>
          </div>
        </div>
        <form class="validate-form" (ngSubmit)="createAccount.form.valid && createUserAccount()" #createAccount="ngForm"
          novalidate *ngIf="!response.response">
          <h2>Create Church account</h2>
          <br>
          <br>
          <!--Name of the Church (Organization Name)-->
          <div class="form-floating mb-3">
            <input type="text" class="form-control" id="nameOfOrganization" placeholder="Organization Name"
              autocomplete="off" name="organisationName" [(ngModel)]="account.organisationName"
              #organisationName="ngModel" required  maxlength="150"
              [ngClass]="{ 'is-invalid': createAccount.submitted && organisationName.invalid }" />
            <label for="nameOfOrganization">Church Name (Organisation Name) *</label>
          </div>
          <br>
          <!--Church Admin Name-->
          <div class="form-floating mb-3">
            <input type="text" class="form-control" id="name" placeholder="name" [(ngModel)]="account.name" name="name"
              autocomplete="off" #name="ngModel" [ngClass]="{ 'is-invalid': createAccount.submitted && name.invalid }"
              required maxlength="150"/>
            <label for="name">Name *</label>
          </div>
          <br>
          <!--Church Admin Email Address-->
          <div class="form-floating mb-3">
            <input type="email" class="form-control" id="email" placeholder="Email" [(ngModel)]="account.email"
              name="email" autocomplete="off" #email="ngModel" maxlength="100"
              [ngClass]="{ 'is-invalid': createAccount.submitted && email.invalid }" required email />
            <label for="email">Email *</label>
          </div>
          <br>
          <!--Password-->
          <div class="form-floating mb-3">
            <input type="password" class="form-control" id="password" placeholder="password" name="password"
              [(ngModel)]="account.password" autocomplete="off" #password="ngModel"
              [ngClass]="{ 'is-invalid': createAccount.submitted && password.invalid }" required minlength="8"
              maxlength="16" />
            <label for="password">Password *</label>
          </div>
          <br>
          <!--Confirm Password-->
          <div class="form-floating mb-3">
            <input type="password" class="form-control" id="ConfirmPassword" placeholder="Confirm Password"
              autocomplete="off" name="confirmPassword" [(ngModel)]="account.confirmPassword" #confirmPassword="ngModel"
              [ngClass]="{ 'is-invalid': createAccount.submitted && confirmPassword.invalid }" required  maxlength="16"/>
            <label for="ConfirmPassword">Confirm Password *</label>
            <div *ngIf="passwordMissmatch && account.confirmPassword!==account.password" class="invalid-feedback">
              <div>{{passwordMissmatch}}</div>
            </div>
          </div>
          <br>
          <div class="validationbox" *ngIf="createAccount.submitted && createAccount.invalid">
            <div *ngIf="createAccount.submitted && organisationName.invalid" class="invalid-feedback">
              <div *ngIf="organisationName.errors.required">ChurchName is required</div>
            </div>
            <div *ngIf="createAccount.submitted && name.invalid" class="invalid-feedback">
              <div *ngIf="name.errors.required">Name is required</div>
            </div>
            <div *ngIf="createAccount.submitted && email.invalid" class="invalid-feedback">
              <div *ngIf="email.errors.required">Email is required</div>
              <div *ngIf="email.errors.email">Email must be a valid email address</div>
            </div>
            <div *ngIf="createAccount.submitted && password.invalid" class="invalid-feedback">
              <div *ngIf="password.errors.required">Password is required</div>
              <div *ngIf="password.errors.minlength">Password must be at least 6 characters</div>
            </div>
            <div *ngIf="createAccount.submitted && confirmPassword.invalid" class="invalid-feedback">
              <div *ngIf="confirmPassword.errors.required">Confirm Password is required</div>
            </div>

          </div>
          <div style="text-align: right;">
            <button class="btn btn-light-gray btn-sm" style="border: 1px solid #ccc;" type="reset"
              (click)="cancel()">Cancel</button>
            &nbsp;
            <button class="btn btn-light-gray btn-sm" style="border: 1px solid #ccc;" type="reset">Reset</button>
            &nbsp;
            <button class="btn btn-default btn-sm" type="submit" [disabled]="loaderStatus">
              <span *ngIf="!loaderStatus">
                Create
              </span>
              <span *ngIf="loaderStatus">
                Loadind...
              </span></button>
          </div>
        </form>
      </div>


      <div class="login100-more" style="background-image: url('../../../assets/img/video-poster.jpg');">

      </div>
    </div>
  </div>
</div>
