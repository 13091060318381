<!-- ================ -->
<footer id="footer" class="light">

  

  <!-- .subfooter start -->
  <!-- ================ -->
  <div class="subfooter">
    <div class="container">
      <div class="row">
        <div class="col-md-6">
          <p>Copyright © 2014 iDea by <a target="_blank" href="https://Churchdiary.in">harp.org</a>. All Rights Reserved</p>
        </div>
        <div class="col-md-6">
          <nav class="navbar navbar-default" role="navigation">
            <!-- Toggle get grouped for better mobile display -->
            <div class="navbar-header">
              <button type="button" class="navbar-toggle" data-toggle="collapse" data-target="#navbar-collapse-2">
                <span class="sr-only">Toggle navigation</span>
                <span class="icon-bar"></span>
                <span class="icon-bar"></span>
                <span class="icon-bar"></span>
              </button>
            </div>
            <div class="collapse navbar-collapse" id="navbar-collapse-2">
              <ul class="nav navbar-nav">
                <li><a href="index.html">Home</a></li>
                <li><a href="page-about.html">Tob Features</a></li>
                <li><a href="blog-right-sidebar.html">Create account</a></li>
                <li><a href="portfolio-3col.html">Request Demo</a></li>
                <li><a href="page-contact.html">Blog</a></li>
                <li><a href="page-contact.html">Contact Us</a></li>
              </ul>
            </div>
          </nav>
        </div>
      </div>
    </div>
  </div>
  <!-- .subfooter end -->

</footer>
<!-- footer end -->
