import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ResponseModel } from 'src/app/models/shared/response.model';

@Injectable({
  providedIn: 'root',
})
export class HttpService {
  constructor(private http: HttpClient) {}

  get(url: string) {
    let headers = this.createRequestHeader();
    return this.http.get(url, { headers: headers });
  }

  post(url: string, data: any) {
    let headers = this.createRequestHeader();
    return this.http.post<ResponseModel<string>>(url, data, { headers: headers });
  }

  private createRequestHeader() {
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Access-Control-Allow-Methods': 'GET, POST, DELETE, PUT',
    });

    return headers;
  }
}
