import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { UnderConstructionComponent } from './under-construction/under-construction.component';

const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./home/home.module').then(m => m.HomeModule)
  },
  {
    path: 'account',
    loadChildren: () => import('./account/account.module').then(m => m.AccountModule)
  },
  {
    path: 'features',
    loadChildren: () => import('./Feature/feature.module').then(m => m.FeatureModule)
  },
  {
    path: 'request-demo',
    loadChildren: () => import('./request-demo/request-demo.module').then(m => m.RequestDemoModule)
  }
  ,
  {
    path: '**',
    component:UnderConstructionComponent
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
