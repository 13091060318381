<header class="header full-width transparent fixed header-small clearfix">
    <div class="container">
        <div class="row">
            <div class="col-md-3">

                <!-- header-left start -->
                <!-- ================ -->
                <div class="header-left clearfix">

                    <!-- logo -->
                    <div class="logo">
                        <a href="/"><img id="logo" src="assets/img/churchdiary.png" alt="iDea"></a>
                    </div>

                </div>
                <!-- header-left end -->

            </div>
            <div class="col-md-9">

                <!-- header-right start -->
                <!-- ================ -->
                <div class="header-right clearfix">

                    <!-- main-navigation start -->
                    <!-- ================ -->
                    <div class="main-navigation animated">

                        <!-- navbar start -->
                        <!-- ================ -->
                        <nav class="navbar navbar-default" role="navigation">
                            <div class="container-fluid">

                                <!-- Toggle get grouped for better mobile display -->
                                <div class="navbar-header">
                                    <button type="button" class="navbar-toggle" data-toggle="collapse"
                                        data-target="#navbar-collapse-1">
                                        <span class="sr-only">Toggle navigation</span>
                                        <span class="icon-bar"></span>
                                        <span class="icon-bar"></span>
                                        <span class="icon-bar"></span>
                                    </button>
                                </div>

                                <!-- Collect the nav links, forms, and other content for toggling -->
                                <div class="collapse navbar-collapse" id="navbar-collapse-1">
                                    <ul class="nav navbar-nav navbar-right">
                                        <li>
                                            <a href="/">Home</a>
                                        </li>
                                        <!-- mega-menu start -->
                                        <li>
                                            <a [routerLink]="['/features/churchdashboard']" routerLinkActive="router-link-active" >Top Features</a>
                                        </li>
                                        <!-- mega-menu end -->
                                        <!-- mega-menu start -->
                                        <li>
                                            <a [routerLink]="['/account']" routerLinkActive="router-link-active">Create Account</a>
                                        </li>
                                        <!-- mega-menu end -->
                                        <li>
                                            <a [routerLink]="['/request-demo/request']" routerLinkActive="router-link-active">Request a Demo</a>
                                        </li>

                                        <li>
                                            <a [routerLink]="['/blog']" routerLinkActive="router-link-active">Blog</a>
                                        </li>

                                        <li>
                                            <a [routerLink]="['/contactus']" routerLinkActive="router-link-active">Contact Us</a>
                                        </li>
                                    </ul>
                                </div>

                            </div>
                        </nav>
                        <!-- navbar end -->
                    </div>
                    <!-- main-navigation end -->
                </div>
                <!-- header-right end -->
            </div>
        </div>
    </div>
</header>


<div class="create-account" *ngIf="showCreateAccount">
  <app-create-account (closeCreateAccountWindow)="showCreateAccount=false"></app-create-account>
</div>
